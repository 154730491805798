//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { formgGlobalFormData } from '@/api/modular/flowable/formManage'
import { flowableInstanceFormData } from '@/api/modular/flowable/instanceManage'
import allCustomForm from '@/views/main/customForm/index.js'

export default {
  data() {
    return {
      allCustomFormComps: allCustomForm,
      jsonData: {},
      visible: false,
      confirmLoading: false,
      cardLoading: false,
      // 是否為自定義表單
      isCustomForm: false,
      formCompKey: ''
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    seeOpen(record) {
      this.visible = true
      this.cardLoading = true
      this.formgGlobalFormData(record)
    },
    /*
     * 判斷字符串是否為 JSON
     * return Boolean
     */
    isJSON(str) {
      if (typeof str === 'string') {
        try {
          const obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            return false
          }
        } catch (e) {
          return false
        }
      }
    },
    /**
     * 獲取表單數據
     */
    formgGlobalFormData(record) {
      const params = { processDefinitionId: record.procDef.id }
      formgGlobalFormData(params).then(res => {
        if (!res.success) {
          this.cardLoading = false
          this.$message.error(res.message)
          return
        }

        this.isCustomForm = !this.isJSON(res.data)

        // 自定義表單流程
        if (this.isCustomForm) {
          console.log('自定義的表單')
          this.formCompKey = res.data
          this.flowableInstanceFormData(record)
        } else {
          // 在線設計表單流程
          this.jsonData = JSON.parse(res.data)
          this.flowableInstanceFormData(record)
        }
        // if (res.success) {
        //   this.jsonData = JSON.parse(res.data)
        //   this.flowableInstanceFormData(record)
        // } else {
        //   this.cardLoading = false
        //   this.$message.error(res.message)
        // }
      })
    },

    /**
     * 流程實例中表單的數據
     */
    flowableInstanceFormData(record) {
      const params = { id: record.id }
      flowableInstanceFormData(params).then(res => {
        this.cardLoading = false
        if (res.success) {
          if (res.data.formData === undefined) {
            this.$message.warning('未找到表單數據')
          } else {
            setTimeout(() => {
              this.isCustomForm
                ? this.$refs.customFormRef.init(res.data.formData)
                : this.$refs.kfb.setData(JSON.parse(res.data.formData))
            }, 100)
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /**
     * 關閉界面，置空表單
     */
    handleCancel() {
      this.jsonData = {}
      this.visible = false
    }
  }
}
